import React, { useState, useEffect, useRef } from "react";
import revampData from '../../data/revampData.json';
import MobHighlights from "../UseCases/MobHighlights";
const HomeCategoriesHighlight = () => {
  const categories = revampData.data.listEventCategoriesCarousel.data;
  const reorderByOrderNo = data => {
    return data.sort((a, b) => parseInt(a.orderNo) - parseInt(b.orderNo));
  };
  const reorderedData = reorderByOrderNo(categories);
  const [activeIndex, setActiveIndex] = useState(0);
  const previewCarouselRef = useRef(null);
  const visibleItems = 2.6; // Show 2 full images and part of the third
  const extendedCategories = [...reorderedData, ...reorderedData];
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % reorderedData.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [reorderedData.length]);
  useEffect(() => {
    if (previewCarouselRef.current) {
      const scrollAmount = activeIndex * 150; // Adjust based on preview image width
      previewCarouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth"
      });
    }
  }, [activeIndex]);
  return <>
    <div className="highlight-carousel-container d-none d-sm-block py-3 px-3">
      <div key={reorderedData[activeIndex].id} // Ensure the key changes with activeIndex
      className="carousel-item-container d-flex align-items-center justify-content-center" style={{
        backgroundImage: `url(${reorderedData[activeIndex].highlight.highlightImage})`
      }}>
        {/* Text Container */}
        <div className="text-container text-start">
          <h3 className="display-5 highlight-heading">
            {reorderedData[activeIndex].highlight.title}
          </h3>
          <p className="lead">{reorderedData[activeIndex].highlight.subTitle}</p>
        </div>

        {/* Preview Carousel */}
        <div className="preview-carousel-container d-flex gap-3" ref={previewCarouselRef}>
          <div className="slider-container">
            <div className="slider-track" style={{
              transform: `translateX(-${activeIndex * (100 / visibleItems)}%)`,
              transition: "transform 0.5s ease-in-out"
            }}>
              {extendedCategories.filter((_, index) => index !== activeIndex).map((category, index) => <div key={category.id} className={`slider-item ${index === activeIndex ? "active" : ""}`} style={{
                animation: index === activeIndex ? "moveForward 1s ease-in-out" : "moveForward 1s ease-in-out"
              }}>
                    <img src={category.highlight.highlightImage} alt={category.highlight.title} className="slider-image" />
                  </div>)}
            </div>
          </div>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="progress-bar-container d-flex justify-content-center mt-3">
        {reorderedData.map((_, index) => <div key={index} className={`progress-bar-item ${index === activeIndex ? "active" : ""}`} onClick={() => setActiveIndex(index)}></div>)}
      </div>
      <style jsx>{`
        .highlight-carousel-container {
          max-width: 1200px;
          margin: auto;
        }

        .carousel-item-container {
          position: relative;
          max-width: 1001px;
          width: 100%;
          background-size: 100%; // Initial background size
          background-position: center;
          height: 500px;
          border-radius: 10px;
          color: white;
          display: flex;
          padding: 0px;
          margin: auto;
          animation: zoomIn 1s ease-in-out;
        }

        .text-container {
          max-width: 50%;
          padding: 20px;
          padding-left: 55px;
          border-radius: 10px;
          text-align: center;
          margin-right: 101px;
        }
        .highlight-heading{
        font-weight: 600;
        line-height: 48px;
        font-size: 40px;
        
        }
        .lead{
        font-weight: 500;
        line-height: 34px;
        font-size: 20px;
        }

        .preview-carousel-container {
          display: flex;
          gap: 10px;
          max-width: 400px;
        }

        .preview-image-container {
          width: 140px;
          height: 200px;
          border-radius: 16px;
          overflow: hidden;
          border: 1px solid #ffffff;
          background: linear-gradient(
            178.92deg,
            rgba(0, 0, 0, 0) 31.43%,
            rgba(0, 0, 0, 0.8) 98.75%
          );
        }

        .preview-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }

        .progress-bar-container {
          display: flex;
          gap: 10px;
          justify-content: center;
          position: relative;
          top: -50px;
        }

        .progress-bar-item {
          width: 50px;
          height: 5px;
          background-color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .progress-bar-item.active {
          background-color: white;
        }
        .preview-image-container.active {
          transform: scale(1.1);
          opacity: 1;
        }

        .preview-image-container:not(.active) {
          opacity: 0.6;
        }

        @keyframes zoomIn {
          from {
            background-size: 50%;
          }
          to {
            background-size: 100%;
          }
        }
        .slider-item.active {
          z-index: 1;
        }

        .preview-image-container.active {
          border: 2px solid white;
        }
        slider-item {
          flex: 0 0 calc(100% / ${visibleItems});
          transition:
          transform 0.5s ease,
          opacity 0.5s ease;
        }

        .preview-image-container:not(.active) {
          opacity: 0.6;
        }
        .slider-item {
          flex: 0 0 calc(100% / ${visibleItems});
        }
        @media (max-width: 800px) {
          .text-container {
          margin-right: 10px;
        }
        }
        @media (max-width: 768px) {
          .text-container {
            max-width: 70%;
          }
          .preview-image-container {
            width: 100px;
            height: 60px;
          }
        }
        .slider-container {
          display: flex;
          overflow: hidden;
          width: 100%;
        }

        .slider-track {
          display: flex;
          width: calc(100% * ${reorderedData.length});
          transition: transform 0.5s ease-in-out;
        }

        .slider-item {
          flex: 0 0 calc(100% / ${visibleItems}); 
          box-sizing: border-box;
          padding: 10px;
          position: relative;
          transition:
            transform 0.3s ease-in-out,
            opacity 0.3s ease-in-out;
        }

        .slider-image {
          width: 100%;
          height: 200px;
          border-radius: 10px;
          object-fit: cover;
        }

        .slider-item.active {
          z-index: 1;
        }
        .slider-container {
          display: flex;
          overflow: hidden;
          width: 100%;
        }

        .slider-track {
          display: flex;
          transition: transform 0.5s ease-in-out;
        }

        .slider-item {
          flex: 0 0 calc(100% / ${visibleItems});
          display: flex;
          flex-direction: column;
          align-items: center;
          transition:
            transform 0.5s ease,
            opacity 0.5s ease;
        }

        .slider-image {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 16px;
          border: 1px solid #FFFFFF;
        }

        .slider-container {
          display: flex;
          overflow: hidden;
          width: 100%;
          perspective: 1000px; 
        }

        .slider-track {
          display: flex;
          transition: transform 0.5s ease-in-out;
        }

        .slider-item {
          flex: 0 0 calc(100% / ${visibleItems});
          display: flex;
          flex-direction: column;
          align-items: center;
          transform-origin: center center; 
          transition:
            transform 0.5s ease,
            opacity 0.5s ease;
        }

        .slider-image {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 10px;
        }

        .slider-item.active {
          animation: moveForward 1s ease-in-out;
        }

        .slider-item:not(.active) {
          animation: moveForward 1s ease-in-out;
        }

        @keyframes moveForward {
          0% {
            transform: translateX(50px);
          }
          100% {
            transform: translateX(-0px);
          }
        }
        @media ((min-width: 600px) and ( max-width: 900px)) {
            .slider-image {
            height: 150px;
          }
          .highlight-heading{
            font-size: 30px;
            line-height: 35px;
          }
          .lead{
            font-size: 16px;
            line-height: 23px;
          }
           .carousel-item-container {
             //background-repeat: no-repeat;
             border-radius: 10px !important;
             height: 404px ;
           }
        }
        @media (max-width: 768px) {
          .slider-image {
            height: 150px;
          }
        }
        @media (max-width: 576px) {
          .text-container {
            max-width: 90%;
          }
          .preview-image-container {
            width: 80px;
            height: 50px;
          }
          .carousel-item-container {
            height: 175px;
          }
          .text-container{
            padding-left:18px;
            max-width: 100% !important;
          }
          .slider-image{
              height: 62px;
          }
          .highlight-heading{
            font-size: 12.59px;
            line-height: 15.11px;
          }
          .lead{
            font-size: 6.3px;
            line-height: 10.7px;
          }
          .preview-carousel-container{
            max-width: 160px;
          }
          .progress-bar-item{
            width: 13px;
            height: 2.7px;
          }
          .progress-bar-container{
            margin-top: 33px !important;
            }
            .slider-item{
              padding-right: 4px;
              padding-left: 4px;
            }
        }
      `}</style>
     
    </div>
    <div className="d-block d-sm-none mb-5">
      <MobHighlights data-sentry-element="MobHighlights" data-sentry-source-file="HomeCategoriesHighlight.jsx" />
    </div>
    </>;
};
export default HomeCategoriesHighlight;