import React, { useState, useEffect, useContext } from 'react';
import BadgeCarousel from './BadgeCarousel.jsx';
import Link from "next/link";
import { useRouter } from "next/router";
import { theme, websiteColors } from "../../themes/Theme.js";
import { quizhubUrl } from '../../config/config.js';
import { certhubUrl } from '../../config/config.js';
import { hostEventUrl } from '../../config/config.js';
import AuthContext from "../../contexts/AuthContext";
import { errorToaster, successToaster } from "../../helpers/utils";
import { subscribeUser, getUserById } from "../../crud/users.crud";
import { useFormik } from "formik";
import * as Yup from "yup";
import AnimatedLogo from '../../common/AnimatedLogo.js';
const HomeFooter = () => {
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const {
    route
  } = useRouter();
  const {
    signedIn
  } = useContext(AuthContext);
  const clientType = 1;
  const subscriptionSource = route === "/" ? 1 : route === "/resources" ? 5 : route === "/communities" ? 4 : 1;
  useEffect(() => {
    if (signedIn === true) {
      getProfile();
    } else if (signedIn === false && (route === "/" || route === "/resources" || route === "/communities")) {
      setTimeout(() => {
        setIsSubscribed(false);
      }, 60000);
    }
  }, [signedIn]);
  const getProfile = async () => {
    try {
      const res = await getUserById();
      if (res.data.email_subscription) setIsSubscribed(true);
      if (res.data.email_subscription == false && (route === "/" || route === "/resources" || route === "/communities")) {
        setTimeout(() => {
          setIsSubscribed(false);
        }, 60000);
        setUserEmail(res.data.email_id);
        // return () => {
        //     clearInterval(interval);
        // }
      }
    } catch (err) {
      console.log(err);
      setIsSubscribed(false);
    }
  };
  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email_id: userEmail
    },
    validationSchema: Yup.object({
      email_id: Yup.string().required("Email is required").email("Please enter a valid email")
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        let res = await subscribeUser(values.email_id, clientType, subscriptionSource);
        if (res.status === 200) successToaster("Subscribed Successfully");
        if (signedIn === true) {
          getProfile();
        }
        formik.values.email_id = '';
        setLoading(false);
        setIsSubscribed(true);
      } catch (err) {
        console.log(err);
        errorToaster("Something went wrong, could not subscribe user");
        setLoading(false);
        setIsSubscribed(false);
      }
    }
  });

  // Show error toaster when email validation fails
  useEffect(() => {
    if (formik.touched.email_id && formik.errors.email_id) {
      errorToaster(formik.errors.email_id);
      setLoading(false);
    }
  }, [formik.touched.email_id, formik.errors.email_id]);
  const style = {
    textDecoration: "none",
    color: "white"
  };
  return <footer className="footer-container font-figtree text-white py-4" data-sentry-component="HomeFooter" data-sentry-source-file="HomeFooter.jsx">
      <div className="px-lg-5 px-md-5  px-4   py-3">
        <div className="row  pe-0 ps-0  mb-5 ">
          <div className="col-xl-4 col-lg-12 col-12 mb-4 mr-1 ps-0 brand-leftcontainer">
            <div className='d-flex flex-column gap-1'>
              <Link href="/" style={{
              width: '120px'
            }} data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx"> <AnimatedLogo color='white' customClass='mb-2' data-sentry-element="AnimatedLogo" data-sentry-source-file="HomeFooter.jsx" /> </Link>
              <p>AI-Powered Event Platform</p>
            </div>
            <p className="my-4 brand-descr">
              KonfHub is an AI-powered, GDPR-compliant platform for seamless ticketing, secure attendee management, and smooth event operations. Say goodbye to complexity and hello to seamless, powerful event management!
            </p>
            <img src="/img/gdrp-logo.svg" alt="GDPR Certified" className="footer-gdpr mt-3" />
          </div>

          <div className="col-xl-8 col-lg-12 col-12">
            <div className="row footer-links">
              {/* KONFHUB Column */}
              <div className="footer-column col-6 col-sm-2 px-0 col-xl-2 mb-4" style={{
              minWidth: '156px'
            }}>
                <h4 className="footer-typo-heading">KONFHUB</h4>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li><Link style={style} href="/pricing" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Pricing</Link></li>
                  <li><Link style={style} href="/features" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Features</Link> </li>
                  <li className='comprehensive-txt'><Link style={style} href="/payments-settlements" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Payments & Settlements</Link></li>
                  <li className='comprehensive-txt'><Link style={style} href="/ticketing" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Comprehensive Ticketing</Link></li>
                  <li><Link style={style} href="/whitelabeling" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">White Labeling</Link> </li>
                  <li><Link style={style} href="/usecases" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Use Cases</Link> </li>
                  <li><Link style={style} href="/developers" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">For Developers</Link> </li>
                  <li><Link style={style} href="/stripe-connect" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Stripe Connect</Link> </li>
                  <li><Link style={style} href="/tap-payments" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Tap Payments</Link> </li>
                  <li><Link style={style} href="/logos" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Brand Assets</Link> </li>
                </ul>
              </div>

              {/* LEGAL & POLICIES Column */}
              <div className="footer-column lp-column col-6 col-sm-2 px-0 col-lg-2 col-xl-2 mb-4" style={{
              minWidth: '135px',
              maxWidth: '130px'
            }}>
                <h4 className="footer-typo-heading text-nowrap">LEGAL & POLICIES</h4>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li><Link style={style} href="/code-of-conduct" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Code of Conduct</Link></li>
                  <li><Link style={style} href="/privacy-policy" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Privacy Policy</Link></li>
                  <li className='join-txt'><Link style={style} href="/terms-and-conditions" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Terms & Conditions</Link></li>
                  <li><Link style={style} href="/responsible-disclosure" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Responsible Disclosure</Link></li>
                  <li><Link style={style} href="/cancellation-refund-policy" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Cancellation & Refund Policy</Link></li>
                </ul>
              </div>

              {/* SOLUTIONS Column */}
              <div className="footer-column col-6 col-sm-2 mx-lg-2 ml-2 px-0 col-xl-2 mb-4" style={{
              minWidth: '101px'
            }}>
                <div className='d-flex flex-column justify-content-between custom-gap'>
                  <div>
                    <h4 className="footer-typo-heading">SOLUTIONS</h4>
                    <ul className="list-unstyled d-flex flex-column gap-2">
                      <li><Link target='_blank' style={style} href={`${certhubUrl}`} data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">CertHub</Link></li>
                      <li><Link target='_blank' style={style} href={`${quizhubUrl}`} data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">QuizHub</Link></li>
                      <li><Link style={style} href="/checkinapp" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Check-In App</Link></li>
                      <li><Link style={style} href="/sponsorapp" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Sponsor App</Link></li>
                      <li><Link style={style} href="/attendeeapp" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Attendee App </Link></li>
                      <li><Link style={style} href="/aibooth" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">AI Photo Booth </Link></li>
                    </ul>
                  </div>
                  <div className='d-lg-block d-md-none d-none'>
                    <h4 className="footer-typo-heading">COMPANY</h4>
                    <ul className="list-unstyled d-flex flex-column gap-2">
                      <li><Link style={style} href="/about-us" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">About Us</Link></li>
                      <li><Link style={style} href="/community" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Join Our Community</Link></li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* EXPLORE Column */}
              <div className="footer-column col-6 col-sm-2 mx-lg-2 ml-2 px-0 col-xl-2 mb-4" style={{
              minWidth: '95px'
            }}>
                <div className='d-flex flex-column justify-content-between custom-gap'>


                  <div>
                    <h4 className="footer-typo-heading">EXPLORE</h4>
                    <ul className="list-unstyled d-flex flex-column gap-2">
                      <li><Link style={style} href="/events" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Events</Link></li>
                      <li><a style={style} target='_blank' href="https://blog.konfhub.com/">Blogs</a></li>
                      <li><a style={style} target='_blank' href="https://www.youtube.com/@KonfHubTech">Videos</a></li>
                      <li> <Link style={style} href="/resources" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Resources</Link> </li>
                      <li className='invisible'> <Link href="#" style={style} data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Resources</Link> </li>
                      <li className='invisible'> <Link href="#" style={style} data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Resources</Link> </li>
                    </ul>
                  </div>
                  <div className='d-lg-block d-md-none d-none'>
                    <h4 className="footer-typo-heading">CONTACT US</h4>
                    <ul className="list-unstyled d-flex flex-column gap-2">
                      <li><a style={style} href="mailto:support@konfhub.com">Support</a> </li>
                      <li><a style={style} target='_blank' href="https://calendly.com/konfhub/konfhub?month=2024-10">Schedule a Demo</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* COMPANY Column */}
              <div className="footer-column col-6 col-sm-2 mx-2 px-0 col-xl-2 mb-4 d-lg-none d-md-block">
                <h4 className="footer-typo-heading">COMPANY</h4>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li><Link style={style} href="#" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">About Us</Link></li>
                  <li className='join-txt'><Link style={style} href="/events" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">Join Our Community</Link></li>
                </ul>

              </div>

              {/* COMPARE US WITH Column */}
              <div className="footer-column col-6 col-sm-2 px-0 col-lg-2 col-xl-2 mb-4" style={{
              minWidth: '175px'
            }}>
                <h4 className="footer-typo-heading text-nowrap">COMPARE WITH</h4>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li><a target='_blank' style={style} href="https://blog.konfhub.com/konfhub-vs-townscript">Townscript</a></li>
                  <li><a target='_blank' style={style} href="https://blog.konfhub.com/konfhub-vs-eventbrite">Eventbrite</a></li>
                </ul>
              </div>

              {/* CONTACT US Column */}
              <div className="footer-column m-autto mr-md-auto col-6 col-sm-2 mx-2 px-0 col-xl-2 mb-4 mr-md-auto d-lg-none d-md-block">
                <h4 className="footer-typo-heading">CONTACT US</h4>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li><a style={style} href="mailto:support@konfhub.com">Support</a> </li>
                  <li><a style={style} target='_blank' href="https://calendly.com/konfhub/konfhub?month=2024-10">Schedule a Demo</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Second row: Newsletter and recognitions */}
        <div className="row align-items-start my-4">
          <div className="col-lg-8 col-md-6 text-center p-0 text-md-start mb-4 mb-md-0">
            <div className='d-flex flex-column gap-2 align-items-start'>
              <h4 className='footer-typo-heading mb-2'>RECOGNITIONS</h4>
              <div style={{
              width: "100%"
            }} className="d-flex badge-scrll-container flex-wrap justify-content-between  justify-content-md-start gap-3 ">
                {/* <img src="/img/badges/medal_1.webp" alt="Badge 1" className="badge-img" />
                 <img src="/img/badges/medal_2.webp" alt="Badge 2" className="badge-img" />
                 <img src="/img/badges/medal_3.webp" alt="Badge 3" className="badge-img" />
                 <img src="/img/badges/medal_4.webp" alt="Badge 4" className="badge-img" />
                 <img src="/img/badges/medal_5.webp" alt="Badge 5" className="badge-img" />
                 <img src="/img/badges/medal_6.webp" alt="Badge 5" className="badge-img" />
                 <img src="/img/badges/medal_7.webp" alt="Badge 5" className="badge-img" />
                 <img src="/img/badges/medal_8.webp" alt="Badge 5" className="badge-img" /> */}
                <BadgeCarousel data-sentry-element="BadgeCarousel" data-sentry-source-file="HomeFooter.jsx" />
              </div>
            </div>

          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className='d-flex flex-column gap-2'>
              <h4 className='footer-typo-heading'>JOIN OUR NEWSLETTER</h4>
              <div>
                <form className="d-flex input-btn-container">
                  <input type="email" placeholder="Enter your email" className="form-control me-2 pl-1" aria-label="email@website.com" aria-describedby="button-addon2" onChange={e => formik.setFieldValue("email_id", e.target.value)} value={formik.values.email_id} disabled={loading} />
                  <button type="submit" onClick={() => {
                  setLoading(true);
                  formik.handleSubmit();
                }} disabled={loading} className="btn btn-light">
                    Subscribe
                  </button>
                </form>
                <p className="mt-2 newsletter-nospam-txt">* No spam "we promise"!✌️ </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row border-top d-flex flex-row justify-content-between align-items-center pt-4">
          <div className="col-md-6 ps-0 pe-0 col-6">
            <p className="brand-descr-b text-nowrap">
              © {new Date().getFullYear()} KonfHub. All rights reserved.
            </p>

          </div>

          <div className="col-md-6 col-6 ps-0 pe-0 text-center text-md-end social-container">
            <Link href="https://twitter.com/konfhub" target='_blank' className="me-sm-3 me-2" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">
              <img src="/img/x-twitter.svg" alt="X" className="social-icons" />
            </Link>
            <Link target='_blank' href="https://www.linkedin.com/company/konfhub/" className="me-sm-3 me-2" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">
              <img src="/img/linkedIn-logo.svg" alt="LinkedIn" className="social-icons" />
            </Link>
            <Link href="https://www.facebook.com/konfhub/" target='_blank' className="me-sm-3 me-2" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">
              <img src="/img/fb-logo.svg" alt="Facebook" className="social-icons" />
            </Link>
            <Link href="https://www.instagram.com/konfhubevents/" target='_blank' className="me-sm-3 me-2" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">
              <img src="https://d2nnrpx7gk2my2.cloudfront.net/files/672c650e97b04e000924da1b/Socialicon.svg" alt="Instagram" className="social-icons" />
            </Link>
            {/* <Link href="https://konf.me/discord" target='_blank' className="me-sm-3 me-2">
              <img src="/img/discord2.svg" alt="V" className="social-icons" />
             </Link> */}
            <Link target='_blank' href="https://www.youtube.com/@KonfHubTech" data-sentry-element="Link" data-sentry-source-file="HomeFooter.jsx">
              <img src="/img/youtube2.svg" alt="V" className="social-icons" />
            </Link>
          </div>
        </div>

      </div>

      <style jsx>
        {`

        .container {
            max-width: 1200px;
            width: 100%;
        }
        .brand-descr {
            font-size: 16px;
            font-weight: 400;
            line-height: 27.2px;
            color: #d6d6d6;
            max-width: 262px;
        }
        .footer-typo-heading {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 2px;
        }
        .list-unstyled li {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
        .brand-leftcontainer {
            max-width: 350px;
            width: 100%;
        }
        .badge-img{
            height:67.2px;
            width:60px;
        }
        .footer-container {
            background-color: ${websiteColors[theme].primaryWebsiteTheme};
            background-image: url('/img/footer-bg.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .input-btn-container {
            border: 1px solid #D0D5DD; 
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); 
            border-radius:5px;
            padding:4px 4px;

        }
        .brand-leftcontainer {
            max-width: 350px;
            width: 100%;
        }
        input.form-control button.btn{
            padding-top:5px !important;
            padding-bottom:5px !important;
        }

        .form-control{
            border: 0 !important; 
            background-color: transparent !important; 
            color:#fff;
        }
        .newsletter-nospam-txt{
          font-size:15px;
          font-weight:400;
          line-height:24px;
          letter-spacing:1px;
        }
        .link{
          text-decoration:none !important;
          color:#fff !important;
        }
        .form-control:focus{
          box-shadow:0 0 0 !important;
        }

        @media (max-width: 1200px) {
          .brand-leftcontainer {
              max-width: 100% !important;
              width: 100%;
            }
          }
          
          .footer-container {
            //background-image:none;
          }
         
        @media screen and (max-width: 1201px) {
          .brand-descr {
            max-width:100%;
          }
        }
         .badge-scrll-container{
            max-width:650px;
            }

          @media screen and ((min-width:992px) and (max-width: 1225px)) {
            .badge-scrll-container{
            max-width:550px;
            }
         }
        @media (min-width: 768px) and (max-width: 992px) {
          .footer-links {
              display: flex;
              flex-wrap: wrap;
              //column-gap:7px;
              justify-content:space-between;
          }
          .m-autto{
              margin-right: auto !important;
          }
          .footer-container {
              background-image:none;
          }
          .footer-column:nth-child(1) {
              order: 1;
              min-width:150px !important;
          }
          .footer-column:nth-child(2) {
              order: 2;
              min-width:160px !important;
          }
          .footer-column:nth-child(3) {
              order: 3;
          }
          .footer-column:nth-child(4) {
              order: 4;
          }
          .footer-column:nth-child(5) {
              order: 5;
          }
          .footer-column:nth-child(6) {
              order: 6;
              //width: 50%;
          }
          .footer-column:nth-child(7) {
              order: 7;
              //width: 50%;
          }
          .join-txt{
              max-width: 77px;
            }
            .badge-scrll-container{
            max-width:300px;
            }
          }
           @media (min-width: 1440px) {
           .lp-column { 
              min-width: 226px !important;
              //max-width: 130px !important;
            }
          }

          @media (max-width: 576px) {
           .footer-column:nth-child(1) { 
              min-width:146px !important;
            }
            .footer-links {
              display: flex;
              flex-wrap: wrap;
            }
            .footer-column:nth-child(1),
            .footer-column:nth-child(2) {
              order: 1;
              width: 50%;
              min-width: 147px;
            }
            .footer-column:nth-child(3),
            .footer-column:nth-child(4) {
              order: 2;
              width: 50%;
              margin:5px 0px!important;
            }
            .footer-column:nth-child(5),
            .footer-column:nth-child(6) {
              order: 3;
              width: 45%;
              margin:5px 0px !important;
            }
            .footer-column:nth-child(5){
              width:50%;
            }
            .footer-column:nth-child(6){
              min-width: 161px;
            }
            .footer-column:nth-child(7) {
              order: 4;
              width: 48%;
              margin:5px 0px !important;
              min-width: 169px;
            }
            .custom-gap{
              gap:0px !important;
            }
            .social-icons{
              max-width:12px;
              max-height:12px;
            }
            .brand-descr-b{
              font-size:10px;
            }
            .join-txt{
              max-width: 77px;
              white-space:wrap !important;
            }
          }

          @media (max-width: 768px) {
            .footer-container {
              background-image: none;
            }
             .social-icons{
              max-width:12px;
              max-height:12px;
            }
            .brand-descr-b{
                font-size:10px;
            }
          }
          @media ((min-width:990px) and (max-width: 1460px)) {
            .footer-column:nth-child(2) {
              order: 4;
            }
          }
          .custom-gap{
              gap:22px;
          }
          .social-container {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-wrap: nowrap; 
          }


        @media (max-width: 576px) {
            .social-container {
                //justify-content: center; /* Center align for smaller screens */
            }
            button.btn {
                padding: 5px 30px;
            }
            input.form-control{
                padding-left:5px !important;
              }
            .badge-img{
                height:67.2px;
                width:69px;
                object-fit:contain;
            }
          }
          @media (max-width: 480px) {
            .comprehensive-txt {
                  max-width:187px;
               }
            }
          @media (max-width: 373px) {
            .footer-column:nth-child(6) {
                order: 4;
              }
            .footer-column:nth-child(7) {
                order: 3;
                min-width:146px;
              }
            }
        `}
      </style>
    </footer>;
};
export default HomeFooter;